import React, { useReducer } from 'react'
import { GET_CATEGORY_TYPES, GET_EVENT_RESOURCES_GROUPS, GET_FEEDBACKS, GET_RESOURCES_GROUPS, GET_SERVICE_POINTS, SET_LOADING, SET_SIDEBAR, SHOW_SELECT } from '../types'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import ResourceReducer from './resourceReducer'
import ResourceContext from './resourceContext'
import storage from '../../helpers/storage'

const ResourceState = (props) => {

    const navigate = useNavigate()

    const initialState = {
        showSelect: '',
        
        servicePoints: [],
        feedbacks: [],
        categoryTypes: [],
        sidebar: {},
        loading: false,
    }

    const [state, dispatch] = useReducer(ResourceReducer, initialState)

    const logout = async () => {
        storage.clearAuth();
        navigate('/');
    }

    const getSelect = (val) => {
        dispatch({
            type: SHOW_SELECT,
            payload: val
        })
    }


    const getFeedbacks = async () => {
        setLoading()
        try {
            const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/`)
            dispatch({
                type: GET_FEEDBACKS,
                payload: resp.data.data.docs
            })
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                logout()
            }

        }
    }


    const getCategoryTypes = async () => {
        try {
            setLoading()
            const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/category-types`)
            dispatch({
                type: GET_CATEGORY_TYPES,
                payload: resp.data.data.docs
            })
        } catch (error) {

            console.log(error)
        }
    }

    const getServicePoints = async (eventId) => {
        setLoading()
        try {
            const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/service-points?event=${eventId}`, storage.getConfig())
            dispatch({
                type: GET_SERVICE_POINTS,
                payload: resp.data.data.docs
            })

        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                logout()
            }
        }
    }

    const setSidebar = (a, l) => {
        dispatch({
            type: SET_SIDEBAR,
            payload: { active: a, label: l }
        })
    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    return (
        <ResourceContext.Provider value={{
            showSelect: state.showSelect,
            
            servicePoints: state.servicePoints,
            feedbacks: state.feedbacks,
            categoryTypes: state.categoryTypes,
            sidebar: state.sidebar,
            loading: state.loading,
            getFeedbacks,
            getCategoryTypes,
            getServicePoints,
            getSelect,
            setSidebar
        }}
        >
            {props.children}

        </ResourceContext.Provider>
    )
}

export default ResourceState