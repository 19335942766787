import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import UserContext from './userContext'
import UserReducer from './userReducer'
import { CLEAR_SEARCH, GET_USER, GET_USERS, SEARCH_USERS, SET_LOADING, SET_PAGINATION } from '../types'
import Axios from 'axios'
import storage from '../../helpers/storage'


const UserState = (props) => {

    const logout = async () => {
        storage.clearAuth();
        navigate('/');
    }

    const navigate = useNavigate()

    const initialState = {
        users: [],
        user: {},
        search: [],
        pagination: {},
        loading: false
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)

    const getUsers = async (limit, page) => {
        const q = `limit=${limit}&page=${page}`;
        setLoading()
        try {
            const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/users?${q}`, storage.getConfigWithBearer())

            const paginate = {
                page: resp.data.data.page,
                hasNextPage: resp.data.data.hasNextPage,
                hasPreviousPage: resp.data.data.hasPreviousPage,
                nextPage: resp.data.data.nextPage,
                previousPage: resp.data.data.previousPage,
                limit: resp.data.data.limit,
                totalDocs: resp.data.data.totalDocs,
                totalPages: resp.data.data.totalPages
            }

            dispatch({
                type: SET_PAGINATION,
                payload: paginate
            })

            dispatch({
                type: GET_USERS,
                payload: resp.data.data.docs
            })

            console.log(resp)
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                logout()
            }

            console.log(error)
        }
    }

    const setUser = (data) => {
        setLoading()
        dispatch({
            type: GET_USER,
            payload: data
        })
    }

    const searchUser = async (limit, page, keyword) => {
        const q = `limit=${limit}&page=${page}&keyword=${keyword}`;
        try {
            setLoading()
            const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/users?${q}`, storage.getConfigWithBearer())

            const paginate = {
                page: resp.data.data.page,
                hasNextPage: resp.data.data.hasNextPage,
                hasPreviousPage: resp.data.data.hasPreviousPage,
                nextPage: resp.data.data.nextPage,
                previousPage: resp.data.data.previousPage,
                limit: resp.data.data.limit,
                totalDocs: resp.data.data.totalDocs,
                totalPages: resp.data.data.totalPages
            }

            dispatch({
                type: SET_PAGINATION,
                payload: paginate
            })

            dispatch({
                type: SEARCH_USERS,
                payload: resp.data.data.docs
            })
        } catch (error) {

            console.log(error)
        }
    }

    const clearSearch = async () => {

        dispatch({
            type: SEARCH_USERS,
            payload: []
        })

    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    return (
        <UserContext.Provider value={{
            users: state.users,
            user: state.user,
            search: state.search,
            pagination: state.pagination,
            loading: state.loading,
            getUsers,
            searchUser,
            clearSearch,
            setUser,
            setLoading
        }}
        >
            {props.children}

        </UserContext.Provider>
    )
}

export default UserState