import { CLEAR_SEARCH, GET_USER, GET_USERS, SEARCH_USERS, SET_LOADING, SET_PAGINATION } from '../types'

const UserReducer = (state, action) => {

    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false
            }
        case GET_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case SEARCH_USERS:
            return {
                ...state,
                search: action.payload,
                loading: false
            }
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
                loading: false
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }

        default:
            return state
    }

}

export default UserReducer