import { CHECKEDIN, GET_ACCOMODATIONS, SET_ACCOMODATION_ID, GET_EVENTS, GET_PARTICIPANTS, GET_ROOM, GET_ROOMS, GET_ROOMS_BY_ID, GET_SERVICE_POINTS, GET_SESSIONS, SEARCH_PARTICIPANTS, SET_LOADING, SET_PAGINATION, GET_USER_SESSIONS, SET_SESSION_EVENT, SET_SESSION_ID, GET_STATS, GET_RESOURCES_GROUPS, GET_EVENT_RESOURCES_GROUPS, SET_SESSION_EVENT_TITLE, SET_REGULAR_ACCOMODATION, SET_SPECIAL_ACCOMODATION, SET_EVENT_PAGINATION, GET_CENTERS, SET_PARTICIPANT_PAGINATION, SET_RESOURCE_GROUP_PAGINATION, SET_ACCOMODATION_PAGINATION, GET_ACCOMODATION, GET_EVENT, SEARCH } from '../types'

const EventReducer = (state, action) => {

    switch (action.type) {

        case GET_EVENTS:
            return {
                ...state,
                events: action.payload,
                loading: false
            }

        case GET_EVENT:
            return {
                ...state,
                event: action.payload,
                loading: false
            }

        case SET_SESSION_EVENT:
            return {
                ...state,
                sessionEvent: action.payload,
                loading: false
            }
        case SET_SESSION_EVENT_TITLE:
            return {
                ...state,
                sessionEventTitle: action.payload,
                loading: false
            }
        case GET_SESSIONS:
            return {
                ...state,
                sessions: action.payload,
                loading: false
            }
        case SET_SESSION_ID:
            return {
                ...state,
                sessionId: action.payload,
                loading: false
            }
        case GET_USER_SESSIONS:
            return {
                ...state,
                userSession: action.payload,
                loading: false
            }

        case GET_PARTICIPANTS:
            return {
                ...state,
                participants: action.payload,
                loading: false
            }

        case CHECKEDIN:
            return {
                ...state,
                checkedIn: action.payload,
                loading: false
            }

        case GET_SERVICE_POINTS:
            return {
                ...state,
                sPoints: action.payload,
                loading: false
            }

        case SEARCH:
            return {
                ...state,
                search: action.payload,
                loading: false
            }

        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
                loading: false
            }

        case SET_EVENT_PAGINATION:
            return {
                ...state,
                eventPagination: action.payload,
                loading: false
            }

        case SET_PARTICIPANT_PAGINATION:
            return {
                ...state,
                participantPagination: action.payload,
                loading: false
            }

        case SET_RESOURCE_GROUP_PAGINATION:
            return {
                ...state,
                rgPagination: action.payload,
                loading: false
            }

        case SET_ACCOMODATION_PAGINATION:
            return {
                ...state,
                accPagination: action.payload,
                loading: false
            }

        case GET_ACCOMODATIONS:
            return {
                ...state,
                accomodations: action.payload,
                loading: false
            }

        case GET_ACCOMODATION:
            return {
                ...state,
                accomodation: action.payload,
                loading: false
            }

        case SET_REGULAR_ACCOMODATION:
            return {
                ...state,
                regularAccomodation: action.payload,
                loading: false
            }

        case SET_SPECIAL_ACCOMODATION:
            return {
                ...state,
                specialAccomodation: action.payload,
                loading: false
            }

        case GET_ROOMS:
            return {
                ...state,
                rooms: action.payload,
                loading: false
            }

        case GET_ROOMS_BY_ID:
            return {
                ...state,
                accomodationRooms: action.payload,
                loading: false
            }

        case SET_ACCOMODATION_ID:
            return {
                ...state,
                accomodationId: action.payload,
                loading: false
            }

        case GET_RESOURCES_GROUPS:
            return {
                ...state,
                resourceGroups: action.payload,
                loading: false
            }
        case GET_EVENT_RESOURCES_GROUPS:
            return {
                ...state,
                eventResourceGroups: action.payload,
                loading: false
            }

        case GET_CENTERS:
            return {
                ...state,
                centers: action.payload,
                loading: false
            }

        case GET_STATS:
            return {
                ...state,
                stats: action.payload,
                loading: false
            }

        case GET_ROOM:
            return {
                ...state,
                room: action.payload,
                loading: false
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }

        default:
            return state
    }

}

export default EventReducer