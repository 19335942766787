import { GET_CATEGORY_TYPES, GET_EVENT_RESOURCES_GROUPS, GET_FEEDBACKS, GET_RESOURCES_GROUPS, GET_SERVICE_POINTS, SET_LOADING, SET_SIDEBAR, SHOW_SELECT } from '../types'

const ResourceReducer = (state, action) => {

    switch (action.type) {
       
        case GET_SERVICE_POINTS:
            return {
                ...state,
                servicePoints: action.payload,
                loading: false
            }
        case GET_CATEGORY_TYPES:
            return {
                ...state,
                categoryTypes: action.payload,
                loading: false
            }
        case GET_FEEDBACKS:
            return {
                ...state,
                feedbacks: action.payload,
                loading: false
            }
        case SHOW_SELECT:
            return {
                ...state,
                showSelect: action.payload,
                loading: true
            }
        case SET_SIDEBAR:
            return {
                ...state,
                sidebar: action.payload,
                loading: true
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }

        default:
            return state
    }

}

export default ResourceReducer