import React, { Suspense, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import "./helpers/interceptor";
import Loader from "./components/layouts/partials/Loader";
import DashboardLayout from "./components/layouts/Dashboard";
import UserState from "./context/users/userState";
import EventState from "./context/events/eventState";
import ResourceState from "./context/resource/resourceState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResourceContext from "./context/resource/resourceContext";

const Login = React.lazy(() => import("./pages/auth/Login"));
const Reset = React.lazy(() => import("./pages/auth/Reset"));
const DashboardDB = React.lazy(() => import("./pages/dashboard/Dashboard"));
const ParticipantsDB = React.lazy(() =>
  import("./pages/dashboard/participants/Participants")
);
const AccomodationsDB = React.lazy(() =>
  import("./pages/dashboard/accomodations/Accomodations")
);
const AccomodationDetailsDB = React.lazy(() =>
  import("./pages/dashboard/accomodations/ViewRoom")
);
const AddAccomodationDB = React.lazy(() =>
  import("./pages/dashboard/accomodations/AddAccomodation")
);
const CommunicationsDB = React.lazy(() =>
  import("./pages/dashboard/communications/Communications")
);
const SessionsDB = React.lazy(() => import("./pages/dashboard/sessions/Sessions"));
const ReportsDB = React.lazy(() => import("./pages/dashboard/reports/Reports"));
const TransactionReportDB = React.lazy(() => import("./pages/dashboard/reports/TransactionReport"));
const AttendanceReportDB = React.lazy(() => import("./pages/dashboard/reports/AttendanceReport"));
const ParticipantsReportDB = React.lazy(() => import("./pages/dashboard/reports/ParticipantsReport"));

const EventsReportDB = React.lazy(() => import("./pages/dashboard/reports/EventsReport"));
const EventsDB = React.lazy(() => import("./pages/dashboard/events/Events"));
const AddEventsDB = React.lazy(() => import("./pages/dashboard/events/AddEvent"));
const EditEventsDB = React.lazy(() => import("./pages/dashboard/events/EditEvent"));

const ResourceGroupsDB = React.lazy(() =>
  import("./pages/dashboard/resourcegroups/ResourceGroups")
);
const UsersDB = React.lazy(() => import("./pages/dashboard/users/Users"));

const App = () => {
  const resourceContext = useContext(ResourceContext)


  return (
    <Router>
      <UserState>
        <EventState>
          <ResourceState>
            <ToastContainer />

            <Suspense fallback={Loader()}>
              <Routes>
                {/* Auth */}
                <Route path="/" exact element={<Login />} />
                <Route path="/login" exact element={<Login />} />
                <Route path="/reset" element={<Reset />} />

                {/* Dashboard */}
                <Route
                  path="/dashboard"
                  element={
                    <DashboardLayout
                      Component={DashboardDB}
                      header="Dashboard"
                      text="Manage all activities"
                      show={resourceContext?.showSelect}
                    />
                  }
                />

                {/* Event routes */}
                
                <Route
                  path="/dashboard/events"
                  element={
                    <DashboardLayout
                      Component={EventsDB}
                      header="Events"
                      text="Manage all events"
                      show={resourceContext?.showSelect}
                    />
                  }
                />

                <Route
                  path="/dashboard/events/create"
                  element={
                    <DashboardLayout
                      Component={AddEventsDB}
                      header="Event"
                      text="Create New Event"
                      show={resourceContext?.showSelect}
                    />
                  }
                />

                <Route
                  path="/dashboard/events/:id"
                  element={
                    <DashboardLayout
                      Component={EditEventsDB}
                      header="Update Event"
                      text="Update Event"
                      show={resourceContext?.showSelect}
                    />
                  }
                />

                <Route
                  path="/dashboard/participants"
                  element={
                    <DashboardLayout
                      Component={ParticipantsDB}
                      header="Participants"
                      text="Manage all participants"
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/sessions"
                  element={
                    <DashboardLayout
                      Component={SessionsDB}
                      header="Sessions"
                      text="Manage all sessions"
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/reports"
                  element={
                    <DashboardLayout
                      Component={ReportsDB}
                      header="Reports"
                      text="Manage all activities"
                      show={resourceContext?.showSelect}
                    />
                  }
                />

                <Route
                  path="/dashboard/reports/events-report"
                  element={
                    <DashboardLayout
                      Component={EventsReportDB}
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/reports/participants-report"
                  element={
                    <DashboardLayout
                      Component={ParticipantsReportDB}
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/reports/attendance-report"
                  element={
                    <DashboardLayout
                      Component={AttendanceReportDB}
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/reports/transactions-report"
                  element={
                    <DashboardLayout
                      Component={TransactionReportDB}
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/resource-groups"
                  element={
                    <DashboardLayout
                      Component={ResourceGroupsDB}
                      header="Resource Groups"
                      text="Manage all resource groups"
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/communications"
                  element={
                    <DashboardLayout
                      Component={CommunicationsDB}
                      header="Communications"
                      text="Manage all communications/feedbacks"
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/accomodations"
                  element={
                    <DashboardLayout
                      Component={AccomodationsDB}
                      header="Accomodations"
                      text="Manage all accomodations"
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/accomodations/add"
                  element={
                    <DashboardLayout
                      Component={AddAccomodationDB}
                      header="Create Accomodation"
                      text="Manage all accomodations"
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/accomodations/:id"
                  element={
                    <DashboardLayout
                      Component={AccomodationDetailsDB}
                      header="Details"
                      text="Manage all accomodations"
                      show={resourceContext?.showSelect}
                    />
                  }
                />
                <Route
                  path="/dashboard/users"
                  element={
                    <DashboardLayout
                      Component={UsersDB}
                      header="Users"
                      text="Manage all users"
                      show={resourceContext?.showSelect}
                    />
                  }
                />
              </Routes>
            </Suspense>
          </ResourceState>
        </EventState>
      </UserState>
    </Router>
  );
};

export default App;
