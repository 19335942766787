import React, { useEffect, useState, useCallback, useContext } from 'react'
import { CiGrid42 } from 'react-icons/ci'
import { AiOutlineUsergroupAdd, AiOutlineBarChart } from 'react-icons/ai'
import { BsChatLeftDots } from 'react-icons/bs'
import { HiUsers, HiOutlineUsers, HiUserGroup } from 'react-icons/hi'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import { BiSolidReport } from 'react-icons/bi'
import { TbCalendarEvent } from 'react-icons/tb'
import { FaHome, FaSpeakerDeck, FaCalendar,FaBed, FaLayerGroup, FaStream, FaChartArea } from 'react-icons/fa'
import {IoHome, IoLogOut} from 'react-icons/io5'
import {ImUsers} from 'react-icons/im'
import { Link, useNavigate } from 'react-router-dom'
import body from '../../helpers/body'
import storage from '../../helpers/storage'
import EventContext from '../../context/events/eventContext'
import ResourceContext from '../../context/resource/resourceContext'

const Sidebar = () => {

  const eventContext = useContext(EventContext)
  const resourceContext = useContext(ResourceContext)

  useEffect(() => {
    body.changeBackground('bg-bck')
    eventContext.getEvents(10, 1)
    eventContext.getUserSession()
    eventContext.getSessions(eventContext.pagination && eventContext.pagination.limit !== undefined ? eventContext.pagination.limit : 10, eventContext.pagination && eventContext.pagination.page !== undefined ? eventContext.pagination.page : 1)
    eventContext.getStats()
    if (storage.getStatus() === active) {
      setActive(storage.getStatus())
    }

    setActive(storage.fetchLegacy('sb-menu') ? storage.fetchLegacy('sb-menu') : 'dashboard');

  }, [])

  const [active, setActive] = useState('')

  const navigate = useNavigate()

  const handleClick = (e, t, url, select) => {

    if (e) { e.preventDefault() }
    storage.keepLegacy('sb-menu', t)

    setActive(t)
    resourceContext.getSelect(active)
    storage.saveStatus(t)
    navigate(`/${url}`)


  }

  const logout = (e) => {
    if (e) e.preventDefault()
    storage.clearAuth()
    navigate('/')
  }

  const superRoutes = [
    { title: 'dashboard', url: 'dashboard', icon: <IoHome />, show: false },
    { title: 'users', url: 'dashboard/users', icon: <HiUsers />, show: false },
    { title: 'events', url: 'dashboard/events', icon: <FaCalendar />, show: false },
    { title: 'participants', url: 'dashboard/participants', icon: <HiUserGroup />, show: true },
    { title: 'accomodation', url: 'dashboard/accomodations', icon: <FaBed />, show: true },
    { title: 'resource group', url: 'dashboard/resource-groups', icon: <FaLayerGroup />, show: true },
    { title: 'sessions', url: 'dashboard/sessions', icon: <FaStream />, show: false },
    { title: 'reports', url: 'dashboard/reports', icon: <FaChartArea />, show: false },
    // { title: 'communications', url: 'dashboard/communications', icon: <BsChatLeftDots />, show: false },
  ]

  const registrationRoutes = [
    { title: 'dashboard', url: 'dashboard', show: false, icon: <CiGrid42 /> },
    { title: 'participants', url: 'dashboard/participants', show: true, icon: <HiOutlineUsers /> },
    { title: 'accomodation', url: 'dashboard/accomodations', show: true, icon: <FaHome /> },
    { title: 'resource group', url: 'dashboard/resource-groups', show: true, icon: <AiOutlineUsergroupAdd /> },
  ]

  const adminRoutes = [
    { title: 'dashboard', url: 'dashboard', show: false, icon: <CiGrid42 /> },
    // { title: 'communications', url: 'dashboard/communications', show: false, icon: <BsChatLeftDots /> },
  ]

  const renderedAdminLinks = adminRoutes.map((route, i) => {
    return (

      <Link key={i} onClick={(e) => handleClick(e, route.title, route.url, route.show)} to={``} className={`flex items-center mb-5 px-5 py-2 rounded-lg ${active === route.title ? 'text-[#320688]' : ''}`}>
        <span className={`${active === route.title ? 'text-[#320688]' : ''} text-sm text-white stroke-2`}>{route.icon}</span>
        <span className="text-white pl-3 text-sm capitalize">{route.title}</span>
      </Link>
    )
  })

  const renderedRegistrationAdminLinks = registrationRoutes.map((route, i) => {
    return (
      <Link
        key={i}
        onClick={(e) => handleClick(e, route.title, route.url, route.show)}
        to={``}
        className={`flex items-center mb-5 px-5 py-2 rounded-lg ${active === route.title ? "text-[#320688]" : ""
          }`}
      >
        <span className="text-white stroke-2">{route.icon}</span>
        <span className="text-white pl-3 text-sm capitalize">
          {route.title}
        </span>
      </Link>
    );
  }
  );
  const renderedSuperLinks = superRoutes.map((route, i) => {
    return (
      <Link key={i} onClick={(e) => handleClick(e, route.title, route.url, route.show)} to={``} className={`flex items-center mb-4 px-5 py-2 rounded-lg ${active === route.title ? 'text-[#320688]' : ''}`}>
        <span className={`${active === route.title ? 'text-[#3c4392] font-bold' : 'text-[#3c4372]'}  text-xl stroke-3`}>{route.icon}</span>
        <span className={`${active === route.title ? 'text-[#3c4392] font-semibold' : 'text-[#3c4372]'} font-medium pl-7 text-sm capitalize`}>{route.title}</span>
      </Link>
    )
  })

  return (
    <>
      <div className="fixed h-screen w-[260px] bg-[#e6f2ff]">

        <div className='flex items-center justify-center h-[65px] bg-[#d6f2ff]'>
          <img src="../../../images/assets/img@logo.png" width={60} alt="" />
         
        </div>

        <div className="pt-7 h-full px-4">
          <div className='mb-12 text-center '>
            {/* <p className="text-white text-xl ps-4 font-bold">CAMS</p> */}
            <p className="text-[#3c4382] text-xs ps-4 capitalize">attendance management system</p>
            <p className="text-[#3c4372] text-xs ps-4 font-bold capitalize pt-3"> {storage.getUser()}</p>
          </div>
          {
            storage.getUserRole() === 'registration-team' &&
            <>
              {renderedRegistrationAdminLinks}
            </>
          }
          {
            storage.getUserRole() === 'superadmin' &&
            <>
              {renderedSuperLinks}
            </>
          }
          {
            storage.getUserRole() === 'site-admin' &&
            <>
              {renderedAdminLinks}
            </>
          }
          <Link onClick={(e) => logout(e)} to={``} className={`flex items-center mb-5 my-12 px-5 py-2 rounded-lg `}>
            <span className="text-[#3c4372] font-semibold text-2xl stroke-2"><IoLogOut /></span>
            <span className="text-[#3c4372] font-medium pl-7 text-sm capitalize">Logout</span>
          </Link>
        </div>
      </div>
    </>
  )
};

export default Sidebar;
