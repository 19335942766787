import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import {
  CHECKEDIN,
  GET_ACCOMODATIONS,
  GET_ACCOMODATION_ID,
  GET_EVENTS,
  GET_PARTICIPANTS,
  GET_ROOMS,
  GET_ROOMS_BY_ID,
  GET_SERVICE_POINTS,
  GET_SESSIONS,
  GET_USER_SESSIONS,
  SEARCH_PARTICIPANTS,
  SET_ACCOMODATION_ID,
  SET_SESSION_EVENT,
  SET_LOADING,
  SET_PAGINATION,
  SET_SESSION_ID,
  GET_STATS,
  GET_RESOURCES_GROUPS,
  GET_EVENT_RESOURCES_GROUPS,
  SET_SESSION_EVENT_TITLE,
  SET_SPECIAL_ACCOMODATION,
  SET_REGULAR_ACCOMODATION,
  SET_EVENT_PAGINATION,
  SET_PARTICIPANT_PAGINATION,
  GET_CENTERS,
  SET_RESOURCE_GROUP_PAGINATION,
  SET_ACCOMODATION_PAGINATION,
  GET_ACCOMODATION,
  GET_EVENT,
  SEARCH,
} from "../types";
import Axios from "axios";
import EventContext from "./eventContext";
import EventReducer from "./eventReducer";
import storage from "../../helpers/storage";

const EventState = (props) => {
  const navigate = useNavigate();

  const initialState = {
    events: [],
    events: {},
    sessionEvent: "",
    sessionId: "",
    sessions: [],
    userSession: {},
    participants: [],
    checkedIn: [],
    search: [],
    accomodations: [],
    accomodation: {},
    accomodationId: "",
    rooms: [],
    regularAccomodation: {},
    specialAccomodation: {},
    accomodationRooms: [],
    resourceGroups: [],
    eventResourceGroups: [],
    centers: [],
    stats: {},
    pagination: {},
    eventPagination: {},
    participantPagination: {},
    rgPagination: {},
    accPagination: {},
    loading: false,
  };

  const [state, dispatch] = useReducer(EventReducer, initialState);

  const logout = async () => {
    storage.clearAuth();
    navigate("/");
  };

  const getCenters = async () => {
    setLoading();
    try {
      const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/centers`);

      dispatch({
        type: GET_CENTERS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getEvents = async (limit, page) => {
    const q = `limit=${limit}&page=${page}`;

    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/events?${q}`
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_EVENT_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_EVENTS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getEvent = async (id) => {

    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/${id}`
      );

      dispatch({
        type: GET_EVENT,
        payload: resp.data.data,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };
  

  const searchEvent = async (limit, page, keyword) => {
    const q = `limit=${limit}&page=${page}&keyword=${keyword}`;
    try {
        setLoading()
        const resp = await Axios.get(`${process.env.REACT_APP_BASE_URL}/events?${q}`, storage.getConfigWithBearer())

        const paginate = {
            page: resp.data.data.page,
            hasNextPage: resp.data.data.hasNextPage,
            hasPreviousPage: resp.data.data.hasPreviousPage,
            nextPage: resp.data.data.nextPage,
            previousPage: resp.data.data.previousPage,
            limit: resp.data.data.limit,
            totalDocs: resp.data.data.totalDocs,
            totalPages: resp.data.data.totalPages
        }

        dispatch({
            type: SET_PAGINATION,
            payload: paginate
        })

        dispatch({
            type: SEARCH,
            payload: resp.data.data.docs
        })
    } catch (error) {

        console.log(error)
    }
}

  const setSessionId = (val) => {
    dispatch({
      type: SET_SESSION_ID,
      payload: val,
    });
  };

  const setSessionEvent = (val) => {
    setLoading();
    dispatch({
      type: SET_SESSION_EVENT,
      payload: val,
    });
  };

  const setSessionEventTitle = (val) => {
    setLoading();
    dispatch({
      type: SET_SESSION_EVENT_TITLE,
      payload: val,
    });
  };

  const getParticipants = async (eventId, limit, page) => {
    const q = `event=${eventId}&limit=${limit}&page=${page}`;
    setLoading();

    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/participants?${q}`,
        storage.getConfigWithBearer()
      );
      let data = [];
      const checkedIn = resp.data.data.docs.filter((c) => {
        if (c.checkedIn === false) {
          data.push(c);
        }
      });

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_PARTICIPANT_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: CHECKEDIN,
        payload: data,
      });
      dispatch({
        type: GET_PARTICIPANTS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const searchParticipants = async (eventId, limit, page, keyword) => {
    const q = `event=${eventId}&limit=${limit}&page=${page}&keyword=${keyword}&populate[]=resourceGroups&populate[]=servicePoint&populate[]=room&populate[]=accomodation`;
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/participants?${q}`,
        storage.getConfigWithBearer()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: SEARCH,
        payload: resp.data.data.docs,
      });
    } catch (error) {}
  };

  const clearSearch = async () => {
    dispatch({
      type: SEARCH,
      payload: [],
    });
  };

  const getAccomodations = async (eventId, limit, page) => {
    const q = `event=${eventId}&limit=${limit}&page=${page}`;
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/accomodation?${q}`,
        storage.getToken()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_ACCOMODATION_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_ACCOMODATIONS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.resp && error.resp.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const setRegularAccomodation = async (eventId) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/misc/accomodation-stats/${eventId}`,
        storage.getToken()
      );

      dispatch({
        type: SET_REGULAR_ACCOMODATION,
        payload: resp.data.data.regular,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setSpecialAccomodation = async (eventId) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/misc/accomodation-stats/${eventId}`,
        storage.getToken()
      );

      dispatch({
        type: SET_SPECIAL_ACCOMODATION,
        payload: resp.data.data.special,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAccomodationById = async (id) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/accomodation/${id}`,
        storage.getToken()
      );

      dispatch({
        type: GET_ACCOMODATION,
        payload: resp.data.data,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getRooms = async (id) => {
    setLoading();

    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/accomodation/rooms?populate=accomodation`,
        storage.getToken()
      );

      const filtered = resp.data.data.docs.filter(
        (f) => f.accomodation._id === id
      );

      dispatch({
        type: GET_ROOMS,
        payload: filtered,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
        setLoading(false);
      }
    }
  };

  const getRoomsByAccomodation = async (accomodationId) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/accomodation/rooms${
          accomodationId ? `?accomodation=${accomodationId}` : ""
        }`,
        storage.getToken()
      );

      // dispatch({
      //   type: SET_PAGINATION,
      //   payload:
      // })

      dispatch({
        type: GET_ROOMS_BY_ID,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getSessions = async (limit, page) => {
    const q = `limit=${limit}&page=${page}`;
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/sessions?status=active&${q}`,
        storage.getConfigWithBearer()
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_SESSIONS,
        payload: resp.data.data.docs,
      });

      setSessionId(resp.data.data.docs[0]._id);
      setSessionEvent(resp.data.data.docs[0].event._id);
      setSessionEventTitle(resp.data.data.docs[0].event.title);
      setSpecialAccomodation(resp.data.data.docs[0].event._id);
      setRegularAccomodation(resp.data.data.docs[0].event._id);
      getParticipants(resp.data.data.docs[0].event._id);
      getAccomodations(resp.data.data.docs[0].event._id);
      getResourceGroups(resp.data.data.docs[0].event._id);
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getUserSession = async () => {
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/sessions`,
        storage.getConfigWithBearer()
      );
      dispatch({
        type: GET_USER_SESSIONS,
        payload: resp.data.data.docs[0],
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getAccomodationId = (val) => {
    dispatch({
      type: SET_ACCOMODATION_ID,
      payload: val,
    });
  };

  const getResourceGroups = async (eventId, limit = 10, page = 1) => {
    const q = `event=${eventId}&limit=${limit}&page=${page}`;
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/resource-groups?${q}`
      );

      const paginate = {
        page: resp.data.data.page,
        hasNextPage: resp.data.data.hasNextPage,
        hasPreviousPage: resp.data.data.hasPreviousPage,
        nextPage: resp.data.data.nextPage,
        previousPage: resp.data.data.previousPage,
        limit: resp.data.data.limit,
        totalDocs: resp.data.data.totalDocs,
        totalPages: resp.data.data.totalPages,
      };

      dispatch({
        type: SET_RESOURCE_GROUP_PAGINATION,
        payload: paginate,
      });

      dispatch({
        type: GET_RESOURCES_GROUPS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getEventResourceGroups = async (eventId) => {
    setLoading();
    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/resource-groups?event=${eventId}`
      );
      dispatch({
        type: GET_EVENT_RESOURCES_GROUPS,
        payload: resp.data.data.docs,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  const getStats = async () => {
    setLoading();

    try {
      const resp = await Axios.get(
        `${process.env.REACT_APP_BASE_URL}/misc/dashboard-stats`
      );
      dispatch({
        type: GET_STATS,
        payload: resp.data.data,
      });
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        logout();
      } else if (error && error.response) {
        console.log(error.response);
      }
    }
  };

  const setLoading = () => {
    dispatch({
      type: SET_LOADING,
    });
  };

  return (
    <EventContext.Provider
      value={{
        events: state.events,
        event: state.event,
        centers: state.centers,
        sessionEvent: state.sessionEvent,
        sessionEventTitle: state.sessionEventTitle,
        sessionId: state.sessionId,
        sessions: state.sessions,
        userSession: state.userSession,
        participants: state.participants,
        checkedIn: state.checkedIn,
        pagination: state.pagination,
        eventPagination: state.eventPagination,
        participantPagination: state.participantPagination,
        rgPagination: state.rgPagination,
        accPagination: state.accPagination,
        search: state.search,
        accomodations: state.accomodations,
        accomodation: state.accomodation,
        regularAccomodation: state.regularAccomodation,
        specialAccomodation: state.specialAccomodation,
        rooms: state.rooms,
        accomodationRooms: state.accomodationRooms,
        accomodationId: state.accomodationId,
        resourceGroups: state.resourceGroups,
        eventResourceGroups: state.eventResourceGroups,
        stats: state.stats,
        loading: state.loading,
        getEvents,
        getEvent,
        searchEvent,
        getCenters,
        setSessionEvent,
        setSessionEventTitle,
        getSessions,
        setSessionId,
        getUserSession,
        getParticipants,
        searchParticipants,
        // setPagination,
        clearSearch,
        getAccomodations,
        getAccomodationById,
        setRegularAccomodation,
        setSpecialAccomodation,
        getRooms,
        getRoomsByAccomodation,
        getAccomodationId,
        getEventResourceGroups,
        getResourceGroups,

        getStats,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventState;
