import React, { useState, useEffect } from "react";

import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import EventContext from "../../context/events/eventContext";
import { useContext } from "react";

const DashboardLayout = ({ Component, header, text }) => {
  
  const eventContext = useContext(EventContext);

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="flex h-screen">
        <Sidebar />

        <main className={`pl-[260px] w-screen h-screen dashboard`}>
          <div className={`w-full h-full comp`}>
            <TopBar header={header} text={text} />
            <div className="pt-20 h-full bg-white">
              <Component />

            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DashboardLayout;
